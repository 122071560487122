import React, { useEffect, useRef } from 'react'
import {
  DeliverableOptions,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getKpiGrades,
  PerfRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { Deliverables } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Deliverables'
import { DeliverablesCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/DeliverablesCardTable'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { VStack } from '@revolut/ui-kit'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { useParams } from 'react-router-dom'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'

interface DeliverablesscardInterface extends CommonCardProps {
  setMissingDeliverablesJustification?: React.Dispatch<React.SetStateAction<boolean>>
  showBeforeSubmitCheckError?: boolean
}

export const DeliverablesCard = connect(
  ({
    onHelpClick,
    setMissingDeliverablesJustification,
    showBeforeSubmitCheckError,
  }: DeliverablesscardInterface) => {
    const { employeeId } = useParams<{ employeeId: any }>()
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasDeliverables = !!values.review_data.deliverables
    const ref = useRef<HTMLDivElement>(null)
    const gradesMap = useGetReviewGradesMap()
    const { grades } = useRecommendedGradesContext()
    const { data: employee } = useGetEmployeeWithManagerFilter(
      employeeId,
      'line_manager__id',
    )

    useEffect(() => {
      if (errors.review_data?.deliverables || showBeforeSubmitCheckError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.deliverables, showBeforeSubmitCheckError])

    if (!hasDeliverables) {
      return null
    }

    const deliverablesGrades = getKpiGrades(gradesMap, false)

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.deliverablesGrade
        ? gradesMap[grades?.deliverablesGrade]
        : undefined

    const gradeRecommendation = DeliverableOptions.INTERMEDIATE
    const gradeValue = values.review_data.deliverables?.cards?.[0]?.rating
    const missingJustification =
      !values.review_data.deliverables?.justifications?.[0]?.comment

    if (gradeRecommendation && gradeValue && missingJustification) {
      const recommendationScore = PerfRatingToGraphNumber[gradeRecommendation]
      const valueScore = PerfRatingToGraphNumber[gradeValue]
      setMissingDeliverablesJustification?.(valueScore > recommendationScore)
    } else {
      setMissingDeliverablesJustification?.(false)
    }

    const deliverablesGradesWithExp = getGradesWithExpectations(
      deliverablesGrades,
      gradeRecommendation,
    )

    return (
      <Card
        data={values}
        renderExpandedContent={() => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={deliverablesGradesWithExp}
          />
        )}
        renderExceedingContent={() => (
          <Deliverables
            reviewData={values.review_data}
            deliverablesGrades={deliverablesGradesWithExp}
            showJustificationError={showBeforeSubmitCheckError && missingJustification}
            justificationOnly
          />
        )}
        additionalInfo={
          <VStack space="s-16">
            <NoPersonalGoalsBanner employee={employee} />
            <DeliverablesCardTable gradesMap={gradesMap} />
          </VStack>
        }
        type={CardContentTypes.DELIVERABLES}
        title="Goals"
        finalRating={finalRating}
        icon="Target"
        fields={[
          {
            field: `review_data.deliverables.cards.0`,
            title: 'Contribution and impact',
            grades: deliverablesGradesWithExp,
            gradeRecommendation,
          },
        ]}
        onSelectGrade={grade => {
          values.review_data.deliverables?.cards?.forEach((_, ind) => {
            set(
              values,
              `review_data.deliverables.cards.${ind}.sections.0.value`,
              grade.key,
            )
            set(values, `review_data.deliverables.cards.${ind}.rating`, grade.key)
          })
        }}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.sections?.[0]?.value
          return !!ratingValue && ratingValue === grade.key
        }}
        justification={values?.review_data?.deliverables?.skipped_section_justification}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
