import React, { useEffect } from 'react'
import {
  DeliverableJustificationPreview,
  performanceRatingToFinalGrade,
  Ratings,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Badge,
  Flex,
  InputGroup,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { get, lowerCase, set } from 'lodash'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import {
  CardContentTypes,
  cardHasMissingValues,
  CommonSectionProps,
  getJustificationMessage,
  getRoundedRating,
  GradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import BottomText from '@components/Inputs/partials/BottomText'
import { ExceedingExpectationTooltip } from '@src/pages/Forms/EmployeePerformanceLayout/components/ExceedingExpectationTooltip'
import { DeliverablesJustificationsView } from '@components/ScorecardGeneral/DeliverablesJustificationsView'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'

interface DeliverablesGradeProps {
  isViewMode: boolean
  isChecked: boolean
  ratings: Ratings[]
  option: GradeOption
  onChange: () => void
}

export const DeliverablesGrade = ({
  isViewMode,
  isChecked,
  ratings,
  option,
  onChange,
}: DeliverablesGradeProps) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const gradesMap = values.grade_label_mapping
  const tooltip = useTooltip()

  const { aboveExp, belowExp } = option
  const differFromExp = aboveExp || belowExp

  return (
    <Flex
      backgroundColor={
        differFromExp && isChecked
          ? Token.color.orange_5
          : isChecked
          ? Token.color.greyTone2
          : undefined
      }
      p="s-16"
      justifyContent="space-between"
      data-testid={`deliverables-${lowerCase(option.text)}`}
    >
      <RadioButton
        label={
          <VStack space="s-4" ml="s-16" width="100%">
            <Flex
              alignItems="center"
              gap="s-8"
              width="fit-content"
              {...tooltip.getAnchorProps()}
            >
              <Text variant="primary">{option.text}</Text>
              {!!ratings.length && isViewMode && (
                <Badge
                  minWidth="s-20"
                  height="s-16"
                  backgroundColor={Token.color.deepGrey}
                  style={{ fontWeight: 500 }}
                >
                  {ratings.length}
                  <Tooltip {...tooltip.getTargetProps()} maxWidth={200} placement="top">
                    <VStack>
                      <Text variant="small" fontWeight="bold">
                        {gradesMap[performanceRatingToFinalGrade(ratings[0].value)]}
                      </Text>
                      {ratings.map((rating: Ratings) => (
                        <Text key={rating.value} variant="small">
                          {rating.review?.reviewer?.full_name}
                        </Text>
                      ))}
                    </VStack>
                  </Tooltip>
                </Badge>
              )}
            </Flex>
            {!!option.description?.length && (
              <VStack>
                {option.description.map((item, index) => (
                  <Text key={index} variant="caption" color={Token.color.greyTone50}>
                    {item}
                  </Text>
                ))}
              </VStack>
            )}
          </VStack>
        }
        checked={isChecked}
        disabled={isViewMode}
        disableLabel={false}
        onChange={onChange}
        alignTop
        capitalize={false}
      />
      {!!differFromExp && isChecked && <ExceedingExpectationTooltip grade={option} />}
    </Flex>
  )
}

interface DeliverablesProps extends CommonSectionProps {
  deliverablesGrades: GradeOption[]
  justificationOnly?: boolean
  showJustificationError?: boolean
}

export const Deliverables = connect(
  ({
    reviewData,
    isViewMode = false,
    deliverablesGrades,
    justificationOnly = false,
    showJustificationError,
  }: DeliverablesProps) => {
    const { errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const deliverables = reviewData?.deliverables

    useEffect(() => {
      if (deliverables && !deliverables.justifications?.length && !isViewMode) {
        deliverables.justifications = [{ comment: null, reference_url: null }]
      }
    }, [deliverables])

    const renderError = () => {
      if (!submitFailed) {
        return null
      }

      const hasErrors =
        errors.review_data?.deliverables?.cards?.[0]?.sections &&
        cardHasMissingValues(
          CardContentTypes.DELIVERABLES,
          reviewData?.deliverables?.cards?.[0],
        )

      if (!hasErrors) {
        return null
      }

      return <BottomText error="Please select one of the options" />
    }

    const getFieldDetails = (option: GradeOption) => {
      const ratingValue = get(reviewData, 'deliverables.cards.0.sections.0.value')
      const isChecked =
        deliverablesGrades.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }
    const getViewFieldDetails = (option: GradeOption) => {
      const ratings =
        get(reviewData, 'deliverables.ratings')?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    const firstCard = reviewData?.deliverables?.cards?.[0]?.sections?.[0]
    const selectedRating = firstCard && 'value' in firstCard ? firstCard.value : undefined
    const selectedGrade = deliverablesGrades.find(item => item.key === selectedRating)
    const justificationMessage = getJustificationMessage(false, selectedGrade)

    return (
      <VStack space="s-8" data-testid="deliverables-section">
        {!justificationOnly && (
          <>
            {renderError()}
            <Flex
              backgroundColor={Token.color.greyTone2}
              borderRadius={Token.radius.r16}
              style={{ overflow: 'hidden' }}
            >
              <InputGroup style={{ width: '100%' }}>
                {deliverablesGrades.map((option, optionIdx) => {
                  const { isChecked, ratings } = isViewMode
                    ? getViewFieldDetails(option)
                    : getFieldDetails(option)
                  return (
                    <DeliverablesGrade
                      key={optionIdx}
                      isViewMode={isViewMode}
                      isChecked={isChecked}
                      ratings={ratings}
                      option={option}
                      onChange={() => {
                        reviewData?.deliverables?.cards?.forEach((_, ind) => {
                          set(
                            reviewData,
                            `deliverables.cards.${ind}.sections.0.value`,
                            option.key,
                          )
                          set(reviewData, `deliverables.cards.${ind}.rating`, option.key)
                        })
                      }}
                    />
                  )
                })}
              </InputGroup>
            </Flex>
          </>
        )}
        {isViewMode && !!reviewData?.deliverables?.justifications?.length ? (
          <DeliverablesJustificationsView
            isWidget={false}
            viewJustifications={
              isViewMode
                ? (reviewData?.deliverables
                    ?.justifications as DeliverableJustificationPreview[])
                : undefined
            }
          />
        ) : (
          <>
            <LapeNewTextArea
              rows={2}
              name="review_data.deliverables.justifications.0.comment"
              label={justificationMessage}
              hideOptional
              hasError={showJustificationError}
            />
            {showJustificationError && <BottomText error="Please add justification" />}
          </>
        )}
      </VStack>
    )
  },
)
