import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Button, Flex, Text, VStack, Widget, Token, Link, Box } from '@revolut/ui-kit'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import get from 'lodash/get'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import Icon from '@components/Icon/Icon'
import styled from 'styled-components'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  DeliverableJustificationPreview,
  DeliverableJustificationValue,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { DeliverablesJustificationsView } from './DeliverablesJustificationsView'
import HideIfCommercial from '../HideIfCommercial/HideIfCommercial'
import BottomText from '@components/Inputs/partials/BottomText'

const DeleteButton = styled(IconButton)`
  color: ${Token.color.grey20};
  &:hover {
    color: ${Token.color.grey50};
  }
`

const ItemContainer = styled.div<{ itemId?: number; itemHovered?: number }>`
  padding: 8px;
  border-radius: 16px;
  background-color: ${props =>
    props.itemId === props.itemHovered ? Token.color.grey2 : undefined};
`

const Circle = styled.div`
  width: 22px;
  height: 22px;
  text-align: center;
  color: ${Token.color.grey2};
  background-color: ${Token.color.grey50};
  border-radius: 11px;
  line-height: 22px;
  font-size: 12px;
  font-weight: 500;
`

const Wrapper = ({
  children,
  isWidget,
}: PropsWithChildren<{
  isWidget: boolean
}>) => {
  return isWidget ? <Widget>{children}</Widget> : <Box>{children}</Box>
}

export const deliverablesJustificationPrefill = `Situation:
Task:
Actions:
Results:`

const JustificationLink = ({
  name,
  id,
  errors,
}: {
  name: string
  id: number
  errors: FormError<ReviewScorecardInterface>
}) => {
  const [hasJustificationError, setHasJustificationError] = useState(
    !!(
      errors.review_data?.deliverables?.justifications?.[
        id
      ] as DeliverableJustificationValue
    )?.reference_url,
  )

  return (
    <VStack width="100%">
      <LapeNewInput
        name={`${name}.${id}.reference_url`}
        label="Link to deliverable example"
        renderPrefix={() => <Circle>{id + 1}</Circle>}
        hideOptional
        onAfterChange={() => setHasJustificationError(false)}
        hasError={hasJustificationError}
      />
      {hasJustificationError && (
        <BottomText error="Link should be a valid url (e.g. https://www.figma.com/file/..., https://revolut.atlassian.net/browse/PERF-...)" />
      )}
    </VStack>
  )
}

export const DeliverablesJustifications = ({
  viewMode,
  viewJustifications,
  isWidget,
}: {
  viewMode?: boolean
  viewJustifications?: DeliverableJustificationPreview[]
  isWidget: boolean
}) => {
  const [itemHovered, setItemHovered] = useState<number>()
  const { values, errors } = useLapeContext<ReviewScorecardInterface>()
  const name = 'review_data.deliverables.justifications'
  const deliverables = values.review_data?.deliverables

  useEffect(() => {
    if (deliverables && !deliverables.justifications?.length) {
      deliverables.justifications = [
        { comment: deliverablesJustificationPrefill, reference_url: null },
      ]
    }
  }, [deliverables])

  if (viewMode) {
    return viewJustifications ? (
      <DeliverablesJustificationsView viewJustifications={viewJustifications} />
    ) : null
  }

  if (!deliverables) {
    return null
  }

  const handleAddAnotherLink = () => {
    deliverables.justifications?.push({
      comment: deliverablesJustificationPrefill,
      reference_url: null,
    })
  }

  const handleDelete = (id: number) => {
    deliverables.justifications = deliverables.justifications?.filter((_, i) => i !== id)
  }

  return (
    <Wrapper isWidget={isWidget}>
      <Text variant="primary">Deliverables and justification</Text>
      <Text mt="s-8" use="p" color={Token.color.greyTone50}>
        Please provide examples of deliverables (tasks, tickets, project docs etc) from
        this cycle as performance justification. A minimum of 3 is recommended.
        <HideIfCommercial>
          <Link href="https://revolut.atlassian.net/l/cp/ak9HHDvu" target="_blank">
            {' '}
            See here
          </Link>{' '}
          for more information.
        </HideIfCommercial>
      </Text>
      <VStack mt="s-16" space="s-16">
        {get(values, name)?.map((_: any, id: number) => {
          return (
            <ItemContainer key={`${name}.${id}`} itemId={id} itemHovered={itemHovered}>
              <Flex flexDirection="column" gap="s-8">
                <Flex alignItems="center" gap="s-8">
                  <JustificationLink name={name} id={id} errors={errors} />
                  {id > 0 ? (
                    <DeleteButton
                      onClick={() => {
                        handleDelete(id)
                      }}
                      onMouseOver={() => setItemHovered(id)}
                      onMouseLeave={() => setItemHovered(undefined)}
                    >
                      <Icon type="Delete" size="small" />
                    </DeleteButton>
                  ) : null}
                </Flex>
                <LapeNewTextArea
                  name={`${name}.${id}.comment`}
                  label={'Justification'}
                  hideOptional
                />
              </Flex>
            </ItemContainer>
          )
        })}
        <Button variant="secondary" size="sm" onClick={handleAddAnotherLink}>
          Add example
        </Button>
      </VStack>
    </Wrapper>
  )
}
