import React, { useState } from 'react'
import { Flex, Popup, Text, Token, VStack } from '@revolut/ui-kit'
import {
  BehaviourAssessmentItem,
  BehaviourAssessmentValues,
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewInterface,
  SummarySkillCardJustificationsInterface,
} from '@src/interfaces/performance'
import AssessBehaviourButtons, {
  AssessBehaviourButtonTypes,
} from '@components/AssessButtons/AssessBehaviourButtons'
import CommentsPopupCard from '@components/ColumnInserts/Justification/CommentsPopupCard'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'

interface ScorecardAssessLevelProps {
  content: BehaviourAssessmentItem
  cardIndex: number
  error?: string
  onChange?: (params: ChangeScorecardInterface) => void
  sectionIndex: number
  superpowersCount: number
  touched?: boolean
  type: PerformanceReviewTypes
  viewMode?: boolean
}

export const ScorecardBehaviourLevel = ({
  content,
  cardIndex,
  error,
  onChange,
  sectionIndex,
  superpowersCount,
  touched,
  type,
  viewMode,
}: ScorecardAssessLevelProps) => {
  const [justificationOpen, setJustificationOpen] = useState(false)

  const path = `review_data.${
    type === PerformanceReviewTypes.cultureValuesFit ? 'culture_values' : 'manager_values'
  }.cards`

  const getCheckedItems = (option: BehaviourAssessmentItem) => {
    if (Array.isArray(option.values)) {
      return option.values.map(val => val.value!)
    }

    if (option.value) {
      return [option.value]
    }

    return []
  }

  const getClearedEmptyJustifications = (
    justifications?: SummarySkillCardJustificationsInterface[],
  ) => {
    if (justifications) {
      return justifications.filter(item => !!item.value)
    }

    return []
  }

  const getReviewsList = (values?: BehaviourAssessmentValues[]) => {
    const reviewsList = {
      [AssessBehaviourButtonTypes.positive]: [] as ReviewInterface[],
      [AssessBehaviourButtonTypes.negative]: [] as ReviewInterface[],
      [AssessBehaviourButtonTypes.neutral]: [] as ReviewInterface[],
      [AssessBehaviourButtonTypes.unknown]: [] as ReviewInterface[],
    }

    values?.forEach(review => {
      if (reviewsList[review.value!]) {
        reviewsList[review.value!].push(review.review)
      }
    })

    return reviewsList
  }

  const handleMessageClick = () => {
    // if someone type in comment then change their mind and remove it, value shouldn't remain as '', as it's count invalid by BE
    if (justificationOpen && content.justification?.length === 0) {
      content.justification = null
    }
    setJustificationOpen(!justificationOpen)
  }

  return (
    <>
      <Flex alignItems="flex-start" justifyContent="space-between" width="100%">
        <VStack gap="s-12">
          <Text fontSize="secondary">{content.text}</Text>
          <VStack space="s-12" pl="s-16" color={Token.color.greyTone50}>
            {content.description
              .split(/\n/)
              .filter(item => item.match(/\S/))
              .map((item, ind) => (
                <Text fontSize="caption" key={ind}>
                  {item}
                </Text>
              ))}
          </VStack>
        </VStack>
        <AssessBehaviourButtons
          checked={getCheckedItems(content)}
          disabled={viewMode}
          error={!touched && !!error && !content.value}
          justificationsCount={
            getClearedEmptyJustifications(content?.justifications).length
          }
          name={`assess_btn_${cardIndex}_${sectionIndex}`}
          onChange={value => {
            onChange && onChange({ cardIndex, sectionIndex, value })
          }}
          onMessageClick={handleMessageClick}
          showCounts={viewMode}
          superpowersCount={superpowersCount}
          reviewsList={getReviewsList(content?.values)}
        />
      </Flex>
      {justificationOpen && !viewMode && (
        <LapeNewTextArea
          name={`${path}.${cardIndex}.sections.${sectionIndex}.justification`}
          label="Justification / evidence or specific examples"
        />
      )}
      {viewMode && (
        <Popup
          onClose={() => setJustificationOpen(false)}
          open={justificationOpen}
          variant="bottom-sheet"
        >
          {getClearedEmptyJustifications(content?.justifications).map(
            (justification, i) => {
              return (
                <CommentsPopupCard justification={justification} key={i}>
                  {justification.value}
                </CommentsPopupCard>
              )
            },
          )}
        </Popup>
      )}
    </>
  )
}
