import React, { ReactNode, Ref } from 'react'
import { Avatar, Box, chain, Flex, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import {
  DeliverableOptions,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkippedJustificationsInterface,
} from '@src/interfaces/performance'
import set from 'lodash/set'
import {
  CardContentTypes,
  GradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { relationToString } from '@src/features/Scorecard/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { InfoOutline } from '@revolut/icons'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'
import { CardItem } from '@src/pages/Forms/EmployeePerformanceLayout/CardItem'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'

export interface CardField {
  field: string
  fieldToSelect?: string
  title: string
  grades: GradeOption[]
  gradeRecommendation: DeliverableOptions | AssessBehaviourButtonTypes | null
  cardIndex?: number
}

interface CardProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  type: CardContentTypes
  title: string
  stat?: number
  icon: ResolveIconType
  fields: CardField[]
  isViewMode?: boolean
  onSelectGrade?: (grade: GradeOption, field: CardField) => void
  justification?: string | SkippedJustificationsInterface[]
  isGradeSelectedRule?: (field: string, grade: GradeOption) => boolean
  headerRef?: Ref<HTMLDivElement>
  finalRating?: string
  additionalInfo?: ReactNode
  onHelpClick?: () => void
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
}

export const Card = ({
  data,
  type,
  title,
  stat,
  icon,
  fields,
  isViewMode = false,
  onSelectGrade,
  justification,
  isGradeSelectedRule,
  headerRef,
  finalRating,
  additionalInfo,
  onHelpClick,
  renderExpandedContent,
  renderExceedingContent,
}: CardProps) => {
  const { query } = useQuery()

  const onGradeClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => {
    if (!isViewMode) {
      e.stopPropagation()
      if (onSelectGrade) {
        onSelectGrade(grade, field)
      } else {
        set(data, field.field, grade.key)
      }
    }
  }

  const justifications = Array.isArray(justification) ? justification : []
  const singleViewFilter = !!justifications.length && !!query[Queries.ReviewerId]

  return (
    <Widget>
      <VStack p="s-4" ref={headerRef}>
        <Flex alignItems="center" justifyContent="space-between" p="s-12">
          <Flex alignItems="center" gap="s-16" width="50%">
            <Avatar useIcon={icon} />
            <Flex alignItems="center" gap="s-8" style={{ fontWeight: 600 }}>
              {chain(
                <Flex alignItems="center" gap="s-8">
                  <Text variant="primary" fontWeight={600}>
                    {title}
                  </Text>
                  {onHelpClick && (
                    <InfoOutline
                      cursor="pointer"
                      size={16}
                      color={Token.color.greyTone50}
                      onClick={onHelpClick}
                    />
                  )}
                </Flex>,
                stat && (
                  <Text variant="primary" color={getPercentColor(stat * 100)}>
                    {formatPercentage(stat)}
                  </Text>
                ),
              )}
            </Flex>
          </Flex>
          {finalRating && <Text variant="primary">{finalRating}</Text>}
        </Flex>
        {isViewMode && !!justifications.length && !singleViewFilter && (
          <VStack space="s-8">
            {justifications.map((value, i) => (
              <Box
                key={i}
                p="s-16"
                mx="s-16"
                mb={justifications.length === 1 ? 's-16' : 0}
                data-testid="skip-section"
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <Text variant="primary" use="div" mb="s-4">
                  {value.review.reviewer.display_name} (
                  {relationToString(value.review.reviewer_relation, true)}) skipped this
                  section
                </Text>
                <Text color="grey-tone-50">“{value.value}”</Text>
              </Box>
            ))}
          </VStack>
        )}
        {additionalInfo}
        {(!isViewMode || !singleViewFilter) && (
          <>
            {fields.map((field, ind) => (
              <CardItem
                key={ind}
                data={data}
                field={field}
                isViewMode={isViewMode}
                type={type}
                cardItemIndex={ind}
                onGradeClick={onGradeClick}
                isGradeSelectedRule={isGradeSelectedRule}
                renderExpandedContent={renderExpandedContent}
                renderExceedingContent={renderExceedingContent}
              />
            ))}
          </>
        )}
      </VStack>
    </Widget>
  )
}
