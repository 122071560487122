import React from 'react'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { TeamInterface } from '@src/interfaces/teams'
import {
  onboardingTeamsDepartmentColumn,
  onboardingTeamsHeadcountColumn,
  onboardingTeamsNameColumn,
  onboardingTeamsOwnerColumn,
} from '@src/constants/columns/onboardingTeams'
import { API } from '@src/constants/api'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<TeamInterface>> => ({
  cells: [
    {
      ...onboardingTeamsNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTeamsDepartmentColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTeamsHeadcountColumn(options.onChange),
      width: 100,
    },
    {
      ...onboardingTeamsOwnerColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ReviewTeams = () => {
  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.TEAMS}
        tableName={TableNames.TeamsOnboarding}
        row={row}
        entity="team"
        variant="existingEntities"
      />
    </>
  )
}
