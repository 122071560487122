import React from 'react'

import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { selectorKeys } from '../api'
import { CellTypes, FilterType } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { Tag } from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

type OnboardingRolesColumn = GenericEditableTableColumn<SpecialisationInterface>

export const onboardingRoleNameColumn: OnboardingRolesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="name" />,
})

export const onboardingRoleHeadcountColumn: OnboardingRolesColumn = () => ({
  type: CellTypes.text,
  idPoint: 'data.headcount',
  dataPoint: 'data.headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
})

export const onboardingRoleSeniorityColumn: OnboardingRolesColumn = () => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniorities',
  insert: ({ data }) => {
    const minSeniority = data.data.seniority_min?.name
    const maxSeniority = data.data.seniority_max?.name

    if (!minSeniority || !maxSeniority) {
      return '-'
    }

    return (
      <Tag variant="faded">
        {minSeniority === maxSeniority
          ? minSeniority
          : `${minSeniority} - ${maxSeniority}`}
      </Tag>
    )
  },
})

export const onboardingRoleOwnerColumn: OnboardingRolesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => {
    return (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="owner"
        selector={selectorKeys.employee}
        customLabel={
          data.data.owner ? <UserWithAvatar {...data.data.owner} disableLink /> : '-'
        }
      />
    )
  },
})
