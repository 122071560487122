import React from 'react'

import {
  CommonGenericEditableTableRowOptions,
  GenericEditableTable,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { API } from '@src/constants/api'
import {
  onboardingRoleHeadcountColumn,
  onboardingRoleNameColumn,
  onboardingRoleOwnerColumn,
  onboardingRoleSeniorityColumn,
} from '@src/constants/columns/onboardingRoles'
import { SpecialisationInterface } from '@src/interfaces/roles'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<SpecialisationInterface>> => ({
  cells: [
    {
      ...onboardingRoleNameColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingRoleHeadcountColumn(options.onChange),
      width: 100,
    },
    {
      ...onboardingRoleSeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingRoleOwnerColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ReviewRoles = () => {
  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.SPECIALISATIONS}
        tableName={TableNames.RolesOnboarding}
        row={row}
        entity="role"
        variant="existingEntities"
      />
    </>
  )
}
