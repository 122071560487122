import { ROUTES } from '@src/constants/routes'
import EmployeeTable from '@src/pages/People/PeopleSubTabs/Employees/EmployeeTable'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import TimeOffPolicies from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import TimeOffPublicHolidays from '@src/pages/People/PeopleSubTabs/TimeOffPage/PublicHolidays'
import TimeOffRegimes from '@src/pages/People/PeopleSubTabs/TimeOffPage/Regimes'
import TimeOffRequests from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import { TimeOffCategories } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Categories'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'

export const peopleSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.employees,
    title: 'Employees',
    path: ROUTES.PEOPLE.EMPLOYEES,
    url: ROUTES.PEOPLE.EMPLOYEES,
    permission: PermissionTypes.ViewEmployees,
    component: EmployeeTable,
    icon: 'People',
  },
  {
    id: HomeSectionId.documents,
    title: 'Documents',
    path: ROUTES.PEOPLE.DOCUMENTS,
    url: ROUTES.PEOPLE.DOCUMENTS,
    permission: PermissionTypes.ViewEmployeeDocuments,
    component: AllDocuments,
    icon: 'DocumentsPair',
  },
  {
    id: HomeSectionId.timeOff,
    title: 'Time Off',
    path: ROUTES.PEOPLE.TIME_OFF.ANY,
    url: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
    globalSetting: GlobalSettings.TimeOffEnabled,
    icon: 'CalendarDate',
    subtabs: [
      {
        id: 'requests',
        title: 'Requests',
        path: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        to: ROUTES.PEOPLE.TIME_OFF.REQUESTS,
        component: TimeOffRequests,
        permission: PermissionTypes.ViewTimeOffRequestsOfReports,
      },
      {
        id: 'policies',
        title: 'Policies',
        path: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        to: ROUTES.PEOPLE.TIME_OFF.POLICIES,
        component: TimeOffPolicies,
        permission: PermissionTypes.ViewTimeOffPolicies,
      },
      {
        id: 'regimes',
        title: 'Regimes',
        path: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        to: ROUTES.PEOPLE.TIME_OFF.REGIMES,
        component: TimeOffRegimes,
        permission: PermissionTypes.ViewTimeOffRegimes,
      },
      {
        id: 'public-holidays',
        title: 'Public holidays',
        path: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        to: ROUTES.PEOPLE.TIME_OFF.PUBLIC_HOLIDAYS,
        component: TimeOffPublicHolidays,
        permission: PermissionTypes.ViewPublicHolidays,
      },
      {
        id: 'categories',
        title: 'Categories',
        path: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        to: ROUTES.PEOPLE.TIME_OFF.CATEGORIES,
        component: TimeOffCategories,
        permission: PermissionTypes.ViewTimeOffPolicyCategory,
      },
    ],
  },
]
